<template>
  <section
    class="common-section learner__benefits--section full__width--section"
  >
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="intro-container">
            <div class="heading">
              {{ $t("landing_page.benefits_section.title") }}
            </div>
            <div class="content">
              {{ $t("landing_page.benefits_section.content") }}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 benefits-container">
          <VueSlickCarousel
            v-bind="sliderOptions"
            class="slick__slider--container variant2 benefits-slider"
          >
            <div
              class="spadding-container"
              v-for="item in benefitsList"
              :key="item.id"
            >
              <div class="benefits-inner">
                <div class="item-image">
                  <img :src="item.image" :alt="item.title" />
                </div>
                <div class="item-title">
                  {{ item.title }}
                </div>
                <div class="item-content">
                  {{ item.content }}
                </div>
              </div>
            </div>
          </VueSlickCarousel>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      benefitsList: [
        {
          image: require("@/assets/images/landing/benefits/learn.svg"),
          title: this.$t("landing_page.benefits_section.benefit1.title"),
          content: this.$t("landing_page.benefits_section.benefit1.content")
        },
        {
          image: require("@/assets/images/landing/benefits/apply.svg"),
          title: this.$t("landing_page.benefits_section.benefit2.title"),
          content: this.$t("landing_page.benefits_section.benefit2.content")
        },
        {
          image: require("@/assets/images/landing/benefits/ask.svg"),
          title: this.$t("landing_page.benefits_section.benefit3.title"),
          content: this.$t("landing_page.benefits_section.benefit3.content")
        },
        {
          image: require("@/assets/images/landing/benefits/share.svg"),
          title: this.$t("landing_page.benefits_section.benefit4.title"),
          content: this.$t("landing_page.benefits_section.benefit4.content")
        },
        {
          image: require("@/assets/images/landing/benefits/engage.svg"),
          title: this.$t("landing_page.benefits_section.benefit5.title"),
          content: this.$t("landing_page.benefits_section.benefit5.content")
        }
      ],
      sliderOptions: {
        slidesToShow: 5,
        slidesToScroll: 1,
        draggable: false,
        arrows: false,
        infinite: false,
        autoplay: false,
        responsive: [
          {
            breakpoint: 9999,
            settings: "unslick"
          },
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
              variableWidth: true,
              centerMode: true
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
              variableWidth: true,
              centerMode: true
            }
          }
        ]
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.learner__benefits--section {
  background-color: $brand-primary-100;
  box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
  font-family: $font-family;
  .intro-container {
    .heading {
      @include title-large;
      @include gradient-text;
    }
    .content {
      margin-top: 8px;
      @include h6;
      color: $brand-neutral-700;
    }
  }
  .benefits-container {
    margin-top: 48px;
  }
  .benefits-inner {
    width: 100%;
    border-radius: 32px;
    background-color: $brand-neutral-0;
    padding: 24px;
    @include flex-column-start;
    text-align: left;
    .item-title {
      margin-top: 16px;
      @include h6(500);
      @include gradient-text;
    }
    .item-content {
      margin-top: 4px;
      height: 135px;
      @include subtitle-regular;
      font-weight: 400;
      color: $brand-neutral-700;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .benefits-slider {
    display: flex;
    justify-content: center;
    .spadding-container {
      width: 217px;
      &:not(:first-child) {
        margin-left: 16px;
      }
      .benefits-inner {
        box-shadow: 16px 14px 54px 0px rgba(0, 129, 255, 0.15);
        transition: all ease 0.3s;
        &:hover {
          border-radius: 16px;
          background-color: $brand-primary-50;
          transform: scale(1.05);
          transition: all ease 0.3s;
        }
      }
    }
  }
}

@media only screen and (max-width: 1199px) {
  .benefits-slider {
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 991px) {
  .full__width--section {
    .container {
      max-width: 100%;
      padding-left: 8px;
      padding-right: 8px;
      .row {
        margin-left: -8px;
        margin-right: -8px;
        .col-12 {
          padding-left: 8px;
          padding-right: 8px;
        }
      }
    }
  }
  .learner__benefits--section {
    .intro-container {
      .heading {
        @include h3;
      }
      .content {
        max-width: calc(100% - 136px);
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .learner__benefits--section {
    .intro-container {
      .content {
        max-width: calc(100% - 36px);
      }
    }
  }
}
</style>

<style lang="scss">
@media only screen and (max-width: 991px) {
  .learner__benefits--section {
    .benefits-slider {
      .slick-slide {
        width: 236px;
      }
    }
  }
}
</style>
